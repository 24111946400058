// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.deviceId - b.deviceId;
}

export default {
    state: {
        shipmentsDevicesFullLoad: false,
        shipmentsDevicesLiteLoad: false,
        shipmentsDevices: [],
    },
    actions: {
        fetchShipmentsDevices/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchShipmentsDevices', time: Date.now() / 1000})

                const params = getApiProps('shipmentsDevices', args)
                this.$api.shipmentsDevices.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsDevices', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setShipmentsDevicesLiteLoad', true)
                        commit('setShipmentsDevicesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchShipmentsDevices', inprogress: false})
                    });
            })
        },

        saveShipmentsDevice({dispatch}, device) {
            let fn = (device.deviceId) ? 'updateShipmentsDevice' : 'createShipmentsDevice'
            return dispatch(fn, device);
        },
        createShipmentsDevice({commit}, device) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsDevices')
                this.$api.shipmentsDevices.create(device, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsDevice', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateShipmentsDevice({commit}, device) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsDevices')
                this.$api.shipmentsDevices.update(device.deviceId, device, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateShipmentsDevice', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteShipmentsDevice({commit}, deviceId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('shipmentsDevices')
                this.$api.shipmentsDevices.delete(deviceId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteShipmentsDevice', deviceId)
                            else commit('updateShipmentsDevice', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setShipmentsDevicesFullLoad(state, FullLoad) {
            state.shipmentsDevicesFullLoad = state.shipmentsDevicesFullLoad || FullLoad
        },
        setShipmentsDevicesLiteLoad(state, LitaLoad) {
            state.shipmentsDevicesLiteLoad = state.shipmentsDevicesLiteLoad || LitaLoad
        },

        setShipmentsDevices(state, nShipmentsDevices) {
            nShipmentsDevices = nShipmentsDevices.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nShipmentsDevices.sort(sortByName)
            state.shipmentsDevices = nShipmentsDevices
        },

        updateShipmentsDevices(state, nShipmentsDevices) {
            if (!state.shipmentsDevices.length) {
                nShipmentsDevices = nShipmentsDevices.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nShipmentsDevices.sort(sortByName)
                state.shipmentsDevices = nShipmentsDevices
                // const chunks = arraySplitIntoChunks(nShipmentsDevices)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.shipmentsDevices.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nShipmentsDevices.forEach(function (nDevice) {
                if (nDevice?.name) nDevice.name_ = nDevice.name.toLocaleLowerCase()
                let i = state.shipmentsDevices.findIndex(u => (u.deviceId == nDevice.deviceId))
                if (i < 0) {
                    state.shipmentsDevices.push(nDevice) //(Object.freeze(nDevice))
                } else {
                //if (!state.shipmentsDevicesFullLoad || state.shipmentsDevices[i][changedField] != nDevice[changedField]) {
                    updateObjectByDiff(state.shipmentsDevices[i], nDevice)
                    // delete nDevice.deviceId
                    // nDevice = {...state.shipmentsDevices[i], ...nDevice}
                    // state.shipmentsDevices[i] = nDevice //Object.freeze(nDevice)
                }
            })

        },
        filterShipmentsDevices(state, nShipmentsDevices) {
            // let Ids = state.shipmentsDevices.map(u=> u.deviceId)
            let nIds = nShipmentsDevices.map(u => u.deviceId)
            let removedIds = state.shipmentsDevices.filter(u => !nIds.includes(u.deviceId)).map(u => u.deviceId)
            removedIds.forEach(removedId => {
                let i = state.shipmentsDevices.findIndex(u => (u.deviceId == removedId))
                if (i != -1) {
                    state.shipmentsDevices.splice(i, 1)
                }
            })
        },
        updateShipmentsDevice(state, nDevice) {
            if (nDevice?.name) nDevice.name_ = nDevice.name.toLocaleLowerCase()
            let i = state.shipmentsDevices.findIndex(u => (u.deviceId == nDevice.deviceId))
            if (i < 0) {
                state.shipmentsDevices.push(nDevice) //(Object.freeze(nDevice))
            } else {
            //if (!state.shipmentsDevicesFullLoad || state.shipmentsDevices[i][changedField] != nDevice[changedField]) {
                updateObjectByDiff(state.shipmentsDevices[i], nDevice)
                // delete nDevice.deviceId
                // nDevice = {...state.shipmentsDevices[i], ...nDevice}
                // state.shipmentsDevices[i] = nDevice //Object.freeze(nDevice)
            }
        },
        deleteShipmentsDevice(state, deviceId) {
            let i = state.shipmentsDevices.findIndex(u => (u.deviceId == deviceId))
            if (i != -1) {
                state.shipmentsDevices.splice(i, 1)
            }
        },

        clearShipmentsDevices(state) {
            state.shipmentsDevices = []
            state.shipmentsDevicesFullLoad = false
        },
    },
    getters: {
        isShipmentsDevicesFullLoad(state) {
            return state.shipmentsDevicesFullLoad
        },
        isShipmentsDevicesLiteLoad(state) {
            return state.shipmentsDevicesLiteLoad
        },
        getShipmentsUnits(state, getters) {
            let shipmentsUnits = state.shipmentsDevices.map(d => d.id)
            return getters.units.filter(u => shipmentsUnits.includes(u.id))
        },
        getShipmentsAvailableUnits(state, getters) {
            let availableUnits = state.shipmentsDevices.filter(d => !d?.uncompleted?.length).map(d => d.id)
            return getters.units
                .filter(u => availableUnits.includes(u.id))
        },
    }
}
